@use '../utils' as *;

/*----------------------------------------*/
/*  18. price CSS START
/*----------------------------------------*/
.price{
    &__plr{
        padding: 0 200px;
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        } 
    }
    &__space{
        padding-top: 120px;
        padding-bottom: 40px;
    }      
    &__item{
        background-color: var(--it-common-white);
        box-shadow: 0px 0px 86px 0px rgba(92, 92, 92, 0.04);
        &.active{
            background-color: var(--it-theme-1);
            & .price__icon{
                & span{
                    background-color: var(--it-common-white);
                }
            }
            & .price__top-content{
                border-bottom: 1px solid var(--it-common-white);
            }
        }
    }
    &__top-content{
        border-bottom: 1px solid var(--it-border-1);
        transition: .3s;
    }
    &__icon{
        padding-bottom: 25px;
        & span{
            width: 160px;
            height: 135px;
            background-color: var(--it-theme-1);
            display: inline-block;
            line-height: 162px;
            text-align: center;
            border-bottom-left-radius: 90px;
            border-bottom-right-radius:90px;
            transition: .3s;
            & i{
                font-size: 70px;
                color: var(--it-common-black);
                padding-left: 6px;
            }
        }
    }
    &__title-sm{
        font-weight: 600;
        font-size: 22px;
        font-family: 'Saira', sans-serif;
        margin-bottom: 0;
        padding-bottom: 15px;
        transition: .3s;
        @media #{$md}{          
            font-size: 19px;
        }
        @media #{$xs}{          
            font-size: 18px;
        }
        @media #{$sm}{          
            font-size: 21px;
        }
    }
    &__content-list{
        padding: 10px;
        & ul{
            & li{
                font-size: 18px;
                font-weight: 400;
                list-style-type: none;
                padding-bottom: 30px;
                @media #{$xl}{          
                    padding-bottom: 25px;
                }
                @media #{$md}{          
                    font-size: 15px;
                    padding-bottom: 15px;
                }
                @media #{$xs}{          
                    font-size: 15px;
                    padding-bottom: 15px;
                }
                @media #{$sm}{          
                    font-size: 17px;
                    padding-bottom: 22px;
                }
                &:first-child{
                    font-weight: 600;
                    font-size: 26px;
                    font-family: 'Poppins', sans-serif;
                    color: var(--it-common-black);
                    padding-bottom: 10px;
                    @media #{$md}{          
                        font-size: 40px;
                    }
                    @media #{$xs}{          
                        font-size: 20px;
                    }
                    @media #{$sm}{          
                        font-size: 45px;
                    }
                }
            }
        }
    }
    &__button{
        padding-bottom: 40px;
    }
    &__top-text{
        & P {
            padding-right: 106px;
            @media #{$xxxl}{          
                padding-right: 85px;
            }
            @media #{$xxl}{          
                padding-right:7px;
            }
            @media #{$xl}{          
                padding-right:0;
            }
            @media #{$lg,$md,$xs}{          
                padding-right:0;
                font-size: 15px;
            }
            @media #{$sm}{          
                padding-right:0;
            }
            
        }
    }
    &__shape-1{
        position: absolute;
        top: 38%;
        left: 2%;
        @media #{$xxl}{    
            left:0;
        }
    }
    &__shape-2{
        position: absolute;
        bottom: 8%;
        left: 1%;
    }
    &__shape-3{
        position: absolute;
        top: 7%;
        right: 0;
        @media #{$xl}{    
            top: 2%;
        }
    }
    &__shape-4{
        position: absolute;
        bottom: 2%;
        right: 3%;
        @media #{$xxl}{    
            bottom: -1%;
            right: 0%;
        }
    }
    &__shape-5{
        position: absolute;
        top: 36%;
        right: 6%;
        @media #{$xxl}{    
            right: 2%;
        }
    }
}
.price-2{
    &__plr{
        padding: 0 200px;
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        } 
    }
    &__space{
        padding-top: 70px;
        padding-bottom: 100px;
        @media #{$lg,$md}{
            padding-bottom: 0;
        } 
        @media #{$xs}{
            padding-bottom: 0;
        } 
    }
    &__item{
        padding-bottom: 80px;
        &.active{
            background-color: var(--it-common-black-2);
            border-radius: 10px;
            & .price-2__top-item{
                border-bottom: 1px solid #58503b;
                & i{
                    color: var(--it-common-white);
                }
            }
            & .price-2__top-title{
                color: var(--it-common-white);
            }
            & .price-2__list-title{
                color: var(--it-common-white);
            }
            & .price-2__list{
                & ul{
                    & li{
                        & span {
                            color: var(--it-common-white);
                        }
                        transition: .3s;
                        &:hover{
                            & span{
                                color: var(--it-theme-1);
                            }
                        }
                    }
                }
            }
        }
    }
    &__top-item{
        padding: 75px 54px;
        padding-bottom: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid var(--it-border-1);
        position: relative;
        @media #{$xxl}{          
            padding: 75px 30px;
        }
        @media #{$md}{          
            padding: 75px 40px;
            padding-bottom: 40px;
        }
        @media #{$xs}{          
            padding: 75px 30px;
            padding-bottom: 40px;
        }
        @media #{$sm}{          
            padding: 40px 70px;
        }
        & i{
            font-style: normal;
            font-size: 50px;
            color: var(--it-common-black);
            font-weight: 600;
            & span {
                font-size: 16px;
            }
        }
    }
    &__top-title{
        font-size: 22px;
        font-weight: 700;
        padding-bottom: 5px;
    }
    &__list-title{
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
    }
    &__shape{
        position: absolute;
        right: 0;
        top: 0;
        @media #{$xl}{          
            right: -18px;
            top: -22px;
        }
        @media #{$md}{          
            right: -4%;
            top: -6%;
        }
        @media #{$xs}{          
            right: -7%;
            top: -13%;
        }
        @media #{$sm}{          
            right: -1%;
            top: -4%;
        }
        @media only screen and (min-width:440px) and (max-width:575px){          
            right: -1%;
            top: -1%;
        }
    }
    &__item-shape{
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        @media #{$xxl}{          
            bottom: -6%;
            right: -9%;
        }
        @media #{$xl}{          
            bottom: -6%;
            right: -9%;
        }
    }
    &__icon{
        position:absolute;
        right: 22%;
        top: 24%;
    }
    &__list{
        padding: 40px 54px;
        @media #{$xxl}{          
            padding: 40px 30px;
        }
        @media #{$md}{          
            padding: 40px 40px;
        }
        @media #{$xs}{          
            padding: 40px 30px;
        }
        @media #{$sm}{          
            padding: 40px 70px;
        }
        & ul{
            & li{
                font-size: 18px;
                font-weight: 400;
                list-style-type: none;
                padding-top: 20px;
                @media #{$xl}{          
                    font-size: 15px;
                }
                & i{
                    color: var(--it-common-blue-3);
                    padding-right: 5px;
                }
                &:hover{
                    & span {
                        color: var(--it-common-blue-3);
                    }
                }
                & span {
                    border-bottom: 1px solid var(--it-border-1);
                    display: inline-block;
                    padding-bottom: 7px;
                    transition: .3s;
                }

                @media #{$md}{          
                    font-size: 15px;
                }
                @media #{$xs}{          
                    font-size: 15px;
                }
                @media #{$sm}{          
                    font-size: 17px;
                }
            }
        }
    }
    &__button{
        padding: 0 54px;
        padding-top: 0;
        @media #{$sm}{          
            padding: 0 70px;
        }
    }
}
