.product-designer {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 180px;
    padding-bottom: 90px;
    position: relative !important;
  }


  .product-designer .frame {
    background-color:#ddc;
    border:solid 20px #eee;
    border-bottom-color:#fff;
    border-left-color:#eee;
    border-radius:2px;
    border-right-color:#eee;
    border-top-color:#ddd;
    box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
    box-sizing:border-box;
    display:inline-block;
    margin:10vh 10vw;
    margin-bottom: 0;
    height:80vh;
    padding:8vmin;
    position:relative;
    text-align:center;
   
  }

  .product-designer .frame.black {
    border-color: #343333 #444 #4f4f4f
}
.product-designer .frame.beige {
    border-bottom-color: #c8ad7f; /* Darker beige with brown tint */
    border-left-color: #d2b48c;   /* Tan, a medium beige */
    border-right-color: #d2b48c;  /* Tan */
    border-top-color: #e3c9a8;    /* Lighter beige with brown tint */
}

  
  .options-column {
    width: 25%;
    padding-right: 20px;
  }
  
  .frame-preview-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .frame-details-column {
    width: 25%;
    padding-left: 20px;
  }
  
  .option-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .option-button.active {
    /* color: #bbc71f; */
    border-left: 3px solid #bbc71f;
  }
  
  .size-selection, .color-selection, .finish-selection, .crop-instructions {
    margin-top: 20px;
  }
  
  .size-option, .color-option, .finish-option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .size-option.selected, .color-option.selected, .finish-option.selected {
    border-color: #bbc71f;
  }
  
  .size-icon {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    margin-right: 10px;
  }
  
  .size-text {
    flex-grow: 1;
  }
  
  .frame-preview {
    width: 100%;
    aspect-ratio: 1;
    border: 2px solid #bbc71f;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .frame-display {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .frame-display.black { background-color: #4a4a4a; }
  .frame-display.white { background-color: #f0f0f0; }
  .frame-display.beige { background-color: #d2b48c; }
  
  .frame-thumbnail {
    width: 100px;
    height: 100px;
    border: 2px solid #bbc71f;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .frame-thumbnail img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .detail-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-color: #ccc;
  }
  
  .detail-label {
    flex-grow: 1;
  }
  
  .price-details {
    margin-top: 20px;
  }
  
  .item-price, .grand-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .add-to-cart {
    width: 100%;
    padding: 10px;
    background-color: #e85949 !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .product-designer {
      flex-direction: column;
      padding-top: 40px;
    }
  
    .options-column, .frame-preview-column, .frame-details-column {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .upload-area {
    text-align: center;
}

.file-input {
    display: none;
}

.upload-button {
    display: inline-flex;
    align-items: center;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.upload-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.upload-button i {
    margin-right: 10px;
    font-size: 1.2em;
}

.drag-drop-text {
    margin-top: 15px;
    color: #6c757d;
    font-size: 14px;
}

.upload-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(121, 121, 121, 0.5);
    width: 400px;
    height: 400px;
    border: 4px dashed #e85949;
    position: relative;
    top: 80px;
}

.bottom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ddd;
    z-index: 1000;
}

.navbar-options {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px 0;
    position: relative;
}

.navbar-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: color 0.3s ease;
}

.navbar-button.active {
    color: #007bff; /* Active color */
}

.navbar-label {
    font-size: 12px;
}

.navbar-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #007bff;
    width: 25%;
    height: 5px;
    transition: transform 0.3s ease;
    z-index: 999999;
}

.options-show {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #f4f4f4;
	overflow: hidden;
	transform: translateY(100%);
    transition: transform 0.7s ease;
}

.options-show.slide-up {
	transform: translateY(0);
    
}

.color-selection,
.size-selection,
.finish-selection,
.crop-instructions {
	padding: 20px;
    margin-bottom: 50px;
}

.color-option,
.size-option,
.finish-option {
	display: block;
	margin: 10px 0;
	padding: 10px;
	background: #fff;
	border: 1px solid #ddd;
	cursor: pointer;
}

.color-option.selected,
.size-option.selected,
.finish-option.selected {
	background: #ddd;
}

.size-icon,
.color-icon,
.finish-icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	background: #ddd;
	margin-right: 10px;
}

.crop-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  width: 100%;
}

.zoom-control {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.zoom-slider {
  flex: 1;
  height: 4px;
  -webkit-appearance: none;
  background: #e0e0e0;
  border-radius: 2px;
  outline: none;
}

.zoom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: #2196f3;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;
}

.zoom-slider::-webkit-slider-thumb:hover {
  background: #1976d2;
}

.zoom-label {
  color: #666;
  font-size: 14px;
  min-width: 30px;
}

.crop-control-button {
  background: #f5f5f5;
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.crop-control-button:hover {
  background: #e0e0e0;
}

.apply-crop-button {
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
}

.apply-crop-button:hover {
  background: #1976d2;
}

.center-crop {
	margin: 0 auto;
}

.frame-swiper {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.frame-slide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.edit-frame-btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-frame-btn:hover {
  background: #0056b3;
  transform: translateY(-2px);
}


.cart-thumbnails {
  position: relative;
  top: 80px; /* Above the bottom navbar */
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0px 8px 9px -3px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.cart-thumbnails h4 {
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 10px;
}

.cart-thumbnails-container {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  padding: 5px 10px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.cart-thumbnails-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.cart-thumbnail {
  min-width: 100px;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease;
  flex-shrink: 0;
}

.cart-thumbnail:hover {
  transform: scale(1.05);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
}

.thumbnail-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 6px;
  font-size: 12px;
  text-align: center;
}

@media (min-width: 768px) {
  .cart-thumbnails {
    top: 100px;
    right: 20px;
    left: auto;
    bottom: auto;
    width: 120px;
    border-radius: 8px;
  }

  .cart-thumbnails-container {
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.cart-thumbnail.new-frame {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
}

.cart-thumbnail.new-frame .thumbnail-image {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #666;
}

.cart-thumbnail.new-frame:hover {
  background: #e5e5e5;
}
