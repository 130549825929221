.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 350px;
    height: 440px;
    overflow: hidden;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .frame-absolute{
    position: absolute;
    top: 250px;
    left: 0;
    right: 0;
    text-align: center;
    
  }

  
.slider-2__thumb img{
    width: 300px;
    margin-bottom: 15px;
}

.slider-2__area{
    min-height: 100vh;
}
.hero{
    width: 100%;
    text-align: center;
}


@media (max-width: 768px) {
  .frame-absolute{
    position: absolute;
    top: 158px;
  }
}