.wall {
    position: relative;
    width: 400px;
    height: 400px;
    overflow: hidden;
  }
  
  .nail {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%) rotate3d(1, 1, 0, 30deg);
    z-index: 2;
  }
  
  .nail-head {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #808080, #404040);
    border-radius: 50%;
    box-shadow: 
      2px 2px 5px rgba(0, 0, 0, 0.3),
      inset -2px -2px 5px rgba(0, 0, 0, 0.5),
      inset 2px 2px 5px rgba(255, 255, 255, 0.5);
  }
  
  .nail-shadow {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    /* background: rgba(0, 0, 0, 0.2); */
    filter: blur(2px);
    border-radius: 50%;
    z-index: -1;
  }
  
  .crack {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 600px;
    background: transparent;
    transform-origin: center;
  }
  
  .wall.visible .crack::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: 
      radial-gradient(circle at center, transparent 0%, transparent 20%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.1) 21%, transparent 21%),
      linear-gradient(45deg, transparent 45%, rgba(0, 0, 0, 0.1) 45%, rgba(0, 0, 0, 0.1) 55%, transparent 55%),
      linear-gradient(-45deg, transparent 45%, rgba(0, 0, 0, 0.1) 45%, rgba(0, 0, 0, 0.1) 55%, transparent 55%);
    clip-path: polygon(
      50% 50%, 
      55% 45%, 65% 35%, 75% 25%, 85% 15%, 95% 5%,
      70% 30%, 60% 40%, 50% 50%,
      40% 60%, 30% 70%, 20% 80%, 10% 90%,
      35% 65%, 45% 55%, 50% 50%,
      55% 48%, 70% 30%, 85% 15%,
      65% 35%, 55% 47%, 50% 50%,
      45% 52%, 25% 70%, 5% 85%,
      30% 65%, 47% 53%, 50% 50%
    );
    animation: crack-grow 2s ease-out infinite alternate;
  }


  
  .crack::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: radial-gradient(circle at center, rgba(0, 0, 0, 0.2) 0%, transparent 70%); */
    filter: blur(3px);
    opacity: 0.5;
    animation: shadow-grow 2s ease-out forwards;
  }
  
  .crack1 { transform: translate(-50%, -50%) rotate(15deg); }
  .crack2 { transform: translate(-50%, -50%) rotate(-30deg) scale(0.8); }
  .crack3 { transform: translate(-50%, -50%) rotate(60deg) scale(0.9); }
  .crack4 { transform: translate(-50%, -50%) rotate(-10deg) scale(0.7); }
  
  /* @keyframes crack-grow {
    0% {
      clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%);
    }
    100% {
      clip-path: polygon(
        50% 50%, 
        55% 45%, 65% 35%, 75% 25%, 85% 15%, 95% 5%,
        70% 30%, 60% 40%, 50% 50%,
        40% 60%, 30% 70%, 20% 80%, 10% 90%,
        35% 65%, 45% 55%, 50% 50%,
        55% 48%, 70% 30%, 85% 15%,
        65% 35%, 55% 47%, 50% 50%,
        45% 52%, 25% 70%, 5% 85%,
        30% 65%, 47% 53%, 50% 50%
      );
    }
  } */
  
  @keyframes shadow-grow {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }
  
  @keyframes crack-grow {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
    