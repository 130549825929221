/**
*
* -----------------------------------------------------------------------------
*
* Template : Sorex - Creative Agency React Template
* Author : ordainit
* Author URI : https://ordainit.com/
*
* ------------------------------------------------

**/

@import 'layout/header';
@import 'layout/slider';
@import 'layout/brand';
@import 'layout/feature';
@import 'layout/about';
@import 'layout/counter';
@import 'layout/service';
@import 'layout/project';
@import 'layout/social';
@import 'layout/company';
@import 'layout/cta';
@import 'layout/testimonial';
@import 'layout/work';
@import 'layout/footer';
@import 'layout/video';
@import 'layout/contact';
@import 'layout/newsletter';
@import 'layout/product';
@import 'layout/team';
@import 'layout/job';
@import 'layout/cart';
@import 'layout/faq';
@import 'layout/error';
@import 'layout/price';
@import 'spacing';
@import 'nice-select';
@import 'meanmenu';
@import 'hover-reveal';
@import 'font-awesome-pro';
@import 'flaticon';
@import 'components/theme';
@import 'components/spacing';
@import 'components/buttons';
@import 'components/animation';
@import 'components/preloader';
@import 'components/background';
@import 'components/carousel';
@import 'components/offcanvas'; 
@import 'components/breadcrumb';
@import 'components/accordion';
@import 'components/search';
@import 'components/tab';
@import 'components/modal';
@import 'components/section-title';
@import 'components/meanmenu';

  